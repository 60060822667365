.guide-card {
border: 1px solid black;

}

.guide-image {
  width: 100%;

  max-height: 250px;
}

.card-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap:15;
}

@media (max-width: 600px) {
  .guide-card {
    min-height: 100px;
    min-width: 100px;
  }
  .guide-image {
    /* height: auto;    */
    /* height: '50%'; */
    max-height: 150px;
    min-width: 250px;
    width: 100%;
  }
}
