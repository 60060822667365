.joytech-home {
  /* background: linear-gradient(to right, #000ff0, #8a2be2); */
  height: 100%;
  background: url(../../assets/pictures/computer.png) no-repeat fixed center / cover;
  font-family: Arial, sans-serif;
  color: #333;
  line-height: 1.6;
}

.pop_section {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding: 10px;
}
.pop_image {
  /* min-width: 400px;
  min-height: 400px; */
  height: 400px;
  width: 100%;
  /* width: 600px; */
}
.customers {
  background-color: black;
}
.joytech-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* margin: 20px; */
}

h1 {
  text-align: center;
  font-size: 90px;
}
h2 {
  text-align: center;
  font-size: xx-large;
}
h3 {
  text-align: center;
  font-size: large;
  /* padding: 20px; */
}

.responsive-text {
  max-width: 100%;
  padding: 20px;
  text-align: center;
  font-size: 8vw; /* Heading font size based on viewport width */
  color: white;
  font-style: italic;
}

.responsive-text h1 {
  font-size: 2rem; /* Default font size */
}

.responsive-text p {
  font-size: 1rem; /* Default font size */
}

.professions {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

.profession-card {
  display: flex;
  justify-content: center;
  align-items: center;
}

@keyframes fadeOutRight {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: translate3d(100%, 0, 0);
  }
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 300px; */
  height: 250px;
  /* height: 150vh; */
  /* Increase the height to enable scrolling */
  /* background-color: #f0f0f0; */
}


.autoscroll {
  /* background: 'linear-gradient(to right, #0000FF, #33FF57)'; */
  background: linear-gradient(to right, #000ff0, #8a2be2);
  overflow: hidden;
}


img:last-of-type {
  padding-left: 0;
}

.inner {
  position: relative;
  width: 100%;
  overflow: hidden;
  height: 400px;
}

.wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  /* border: 1px solid black; */
  /* justify-content: center;
  align-items: center; */
}

.customers {
  background: inherit;
  /* border: 1px solid white; */
  /* width: 500px; */
  /* display: flex;
justify-content:center;
align-items: center; */
}

@media (max-width: 600px) {
  .pop_image {
    min-height: 150px;
    width: 100%;
    max-height: 300px;
    height: auto;
    /* min-width: 300px; */
  }
  
  .customers {
    visibility: hidden;
    display: none;
  }
}
