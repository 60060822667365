.auto-scroll-container {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  /* border: 1px solid black; */
  justify-content: center;
  align-items: center;
  padding: 15px;
}
.auto-scroll {
  overflow: auto;
  border: 1px solid #ccc;
  display: flex;
  flex-direction: column;
  gap: 10;
}

.auto-scroll:hover {
  animation-play-state: paused;
}

@keyframes swipeUp {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(100%);
  }
}

@media (max-width: 600px) {
  .auto-scroll {
    width: 100%;
  }
}
